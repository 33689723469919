<template>
  <b-modal
    :id="`detail-report-resell-ticket-modal`"
    size="md"
    centered
    header-bg-variant="light-info"
    hide-footer
    no-close-on-backdrop
    :title="$t('resellTicket.report.details')"
    @show="showHandle"
  >
    <IAmOverlay :loading="loading">
      <validation-observer ref="refFormObserver">
        <b-row
          v-for="item in reportList"
          :key="item.id"
          class="px-2 py-1 d-flex flex-column border-bottom"
        >
          <span>
            {{ $t('resellTicket.form.phone') }}: {{ item.phone }}
          </span>
          <span>
            {{ $t('resellTicket.form.reason') }}: {{ item.reason }}
          </span>
        </b-row>
        <div class="d-flex-center gap-3 mt-1">
          <b-button
            v-if="isChild == true && resellTicket.isActive"
            variant="info"
            @click="handleSubmit"
          >
            {{ $t('resellTicket.btn.deactive') }}
          </b-button>
          <b-button
            variant="secondary"
            @click="handleCancel"
          >
            {{ $t('cancel') }}
          </b-button>
        </div>
      </validation-observer>
    </IAmOverlay>
  </b-modal>

</template>

<script>
import { nextTick, ref, toRefs } from '@vue/composition-api'
import {
  BButton,
  BModal,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'

import IAmOverlay from '@/components/IAmOverlay.vue'

import useResellTicketHandle from '@saleReport/useResellTicketHandle'

export default {
  components: {
    ValidationObserver,
    BModal,
    BRow,
    IAmOverlay,
    BButton,
  },
  props: {
    item: {
      type: [Object, null],
      default: null,
    },
    isChild: {
      type: Boolean,
      default: null,
    },
  },
  setup(props, { emit, root }) {
    const {
      deactiveResellTicket,
      getReportsById,
    } = useResellTicketHandle()

    const reportList = ref([])
    const loading = ref(false)
    const { item: resellTicket } = toRefs(props)
    // methods
    function handleCancel() {
      root.$bvModal.hide('detail-report-resell-ticket-modal')
    }
    function handleSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          root.$bvModal.show('modal-api-loading')
          await deactiveResellTicket(resellTicket.value.id)
          emit('refresh')
          root.$bvModal.hide('modal-api-loading')
        }
      })
      handleCancel()
    }

    function showHandle() {
      reportList.value = []
      nextTick(async () => {
        loading.value = true
        if (resellTicket.value.id) {
          const response = await getReportsById(resellTicket.value.id)
          reportList.value = response
        }
        loading.value = false
      })
    }

    return {
      showHandle,
      handleCancel,
      handleSubmit,
      reportList,
      loading,
      resellTicket,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
