var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "detail-report-resell-ticket-modal",
      "size": "md",
      "centered": "",
      "header-bg-variant": "light-info",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "title": _vm.$t('resellTicket.report.details')
    },
    on: {
      "show": _vm.showHandle
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('validation-observer', {
    ref: "refFormObserver"
  }, [_vm._l(_vm.reportList, function (item) {
    return _c('b-row', {
      key: item.id,
      staticClass: "px-2 py-1 d-flex flex-column border-bottom"
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('resellTicket.form.phone')) + ": " + _vm._s(item.phone) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('resellTicket.form.reason')) + ": " + _vm._s(item.reason) + " ")])]);
  }), _c('div', {
    staticClass: "d-flex-center gap-3 mt-1"
  }, [_vm.isChild == true && _vm.resellTicket.isActive ? _c('b-button', {
    attrs: {
      "variant": "info"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('resellTicket.btn.deactive')) + " ")]) : _vm._e(), _c('b-button', {
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": _vm.handleCancel
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")])], 1)], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }